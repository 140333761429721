'use client';

import Script from 'next/script';

type MicrosoftBingProps = {
  scriptId: string;
};

export function MicrosoftBing({ scriptId }: MicrosoftBingProps): JSX.Element {
  return (
    <>
      <Script id="microsoft-bing-consent-default" strategy="lazyOnload">
        {`
          window.uetq = window.uetq || [];
          window.uetq.push('consent', 'default', {
            'ad_storage': 'denied'
          });
        `}
      </Script>
      <Script id="microsoft-bing" strategy="lazyOnload">{`
  (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${scriptId}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","https://bat.bing.com/bat.js","uetq");
    `}</Script>
    </>
  );
}
